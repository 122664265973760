import React from 'react';
import { navigate, Link } from 'gatsby';

const windowExists = () => typeof window !== `undefined`
const documentExists = () => typeof document !== `undefined`

export const navigateTo = (path) => {
  if (!windowExists() || !documentExists()) return;

  navigate(path);
}


export const CustomLink = (props) => {
  if (!windowExists() || !documentExists()) return null;
  
  return <Link {...props} />
} 