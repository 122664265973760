
import { getCurrentLangKey } from 'ptz-i18n';

export const getLanguage = (languages) => {
  const url = typeof window !== 'undefined' ? window.location.pathname : '';
  const { langs, defaultLangKey } = languages;
  return getCurrentLangKey(langs, defaultLangKey, url);
};

export const getCurrentPath = () => {
  const pathname = typeof window !== 'undefined' ? window.location.pathname : '';
  return pathname;
};

export const getQueryParams = () => typeof window !== 'undefined' ? window.location.search : '';

export const getQueryParam = (name) => {
  if (typeof window === `undefined`) return null;

  const results = new RegExp(`[?&]${name}=([^&#]*)`).exec(window.location.search);
  if (results == null) return null;
  return decodeURI(results[1]) || 0;
}
