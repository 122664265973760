import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';

import ukFlag from '../../images/logos/uk.png';
import colombianFlag from '../../images/logos/colombia.png';
import languageIcon from '../../images/logos/language.png';
import { CustomLink } from '../../utils/navigation';
import { getCurrentPath, getQueryParams } from '../../utils/getters';

const Navbar = ({ langKey }) => {
  const currentPath = getCurrentPath();
  const params = getQueryParams();

  const { formatMessage } = useIntl();
  useEffect(() => {
    $('ul i.fa').on('click', function() {
      $(this).toggleClass('DDopen');
      $(this).closest('ul').find('ul').removeClass('opened');
      $(this).parent().find('> ul').addClass('opened');
      $(this).closest('ul').find('ul').not('.opened').slideUp(350);
      $(this).parent().find('> ul').slideToggle(350);
      $(this).closest('ul').find('i.fa').not(this).removeClass('DDopen');
    });
  }, []);

  return (
    <nav id="main-navigation-wrapper" className="navbar navbar-default slideIn animated">
      <div className="container">
        <div className="navbar-header">
          <div className="logo-menu">
            <CustomLink to={langKey === 'en' ? '/en' : '/'}>
              <img data-src="/images/protecno.png" alt="Protecno" className="lazyload" />
            </CustomLink>
          </div>
          <button type="button" data-toggle="collapse" data-target="#main-navigation" aria-expanded="false" className="navbar-toggle collapsed">
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar" />
            <span className="icon-bar" />
            <span className="icon-bar" />
          </button>
        </div>
        <div id="main-navigation" className="collapse navbar-collapse ">
        <ul className="nav navbar-nav" style={{ position: 'relative', width: '100%' }}>
          <li className="dropdown">
            <CustomLink to={langKey === 'en' ? '/en/about' : '/about'}>
              <FormattedMessage id="aboutUs" values={{ prefix: '' }} />
            </CustomLink>
          </li>
          <li className="dropdown">
            <CustomLink to={`${langKey === 'en' ? '/en/products' : '/products'}?family=adhesivesAndIndustrialSealants`}>
              <FormattedMessage id="products" values={{ prefix: '' }} />
            </CustomLink><i className="fa fa-chevron-down" />
            <ul className="dropdown-submenu">
              <li>
                <CustomLink to={`${langKey === 'en' ? '/en/products' : '/products'}?family=adhesivesAndIndustrialSealants`}>
                  <FormattedMessage id="adhesivesAndIndustrialSealantsMenu" />
                </CustomLink>
              </li>
              <li>
                <CustomLink to={`${langKey === 'en' ? '/en/products' : '/products'}?family=advancedPolymerCoatings`}>
                  <FormattedMessage id="advancedPolymerCoatingsMenu" />
                </CustomLink>
              </li>
              <li>
                <CustomLink to={`${langKey === 'en' ? '/en/products' : '/products'}?family=elastomericThermalInsulations`}>
                  <FormattedMessage id="elastomericThermalInsulationsMenu" />
                </CustomLink>
              </li>
              <li>
                <CustomLink to={`${langKey === 'en' ? '/en/products' : '/products'}?family=plasmaSurfaceTreatment`}>
                  <FormattedMessage id="plasmaSurfaceTreatmentMenu" />
                </CustomLink>
              </li>
            </ul>
          </li>
          <li className="dropdown">
            <CustomLink to={langKey === 'en' ? '/en/services' : '/services'}>
              <FormattedMessage id="services" values={{ prefix: '' }} />
            </CustomLink><i className="fa fa-chevron-down" />
            <ul className="dropdown-submenu">
              <li>
                <CustomLink to={`${langKey === 'en' ? '/en/service' : '/service'}?id=technicalAdvice`}>
                  <FormattedMessage id="technicalAdvice" />
                </CustomLink>
              </li>
              <li>
                <CustomLink to={`${langKey === 'en' ? '/en/service' : '/service'}?id=trainingSeminars`}>
                  <FormattedMessage id="trainingSeminars" />
                </CustomLink>
              </li>
              <li>
                <CustomLink to={`${langKey === 'en' ? '/en/service' : '/service'}?id=epoxicCovering`}>
                  <FormattedMessage id="epoxicCovering" />
                </CustomLink>
              </li>
              <li>
                <CustomLink to={`${langKey === 'en' ? '/en/service' : '/service'}?id=sodaBlasting`}>
                  <FormattedMessage id="sodaBlasting" />
                </CustomLink>
              </li>
            </ul>
          </li>
          <li className="dropdown">
            <CustomLink to={langKey === 'en' ? '/en/multimedia' : '/multimedia'}>
              <FormattedMessage id="multimedia" values={{ prefix: '' }} />
            </CustomLink>
          </li>
          <li>
            <CustomLink to={langKey === 'en' ? '/en/contact' : '/contact'}>
              <FormattedMessage id="contactUs" values={{ prefix: '' }} />
            </CustomLink>
          </li>
          <li className="dropdown hvr-bounce-to-right language-to-right">
            <CustomLink to="/" style={{ padding: '19px 20px' }}>
              <img src={languageIcon} alt={formatMessage({ id: langKey })} style={{ width: 25, height: 25, marginRight: 8 }} />
              LANGUAGE
            </CustomLink><i className="fa fa-chevron-down" />
            <ul className="dropdown-submenu">
              <li>
                <CustomLink to={`/en/${currentPath.replace('en/', '/')}${params}`}>
                  <img data-src={ukFlag} alt="english" className="lazyload" /> English
                </CustomLink>
              </li>
              <li>
                <CustomLink to={`/${currentPath.replace('en/', '/')}${params}`}>                
                  <img data-src={colombianFlag} alt="spanish" className="lazyload"/> Español
                </CustomLink>
              </li>
            </ul>
          </li>
        </ul>
        {/* <a className="header-requestbtn header2-requestbtn header3-requestbtn hvr-bounce-to-right" href="request-quote.html">Request A Quote</a> */}
      </div>
      </div>
    </nav>
  );
};

Navbar.propTypes = {
  langKey: PropTypes.string,
};

export default Navbar;
