import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { IntlProvider, FormattedMessage } from 'react-intl';

import { getCurrentLangKey, getLangs, getUrlForLang } from 'ptz-i18n';

// Own components
import Icon from '../Icon/icon';
import Header from '../Header/header';
import Footer from '../Footer/footer';

// Assets & Misc
import { CustomLink } from '../../utils/navigation';
import { useLanguages } from '../../hooks/useLanguages';

const Layout = ({ i18nMessages, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          languages {
            defaultLangKey
            langs
          }
        }
      }
    }
  `);
  const url = typeof window !== 'undefined' ? window.location.pathname : '';
  const { langs, defaultLangKey } = data.site.siteMetadata.languages;
  const langKey = getCurrentLangKey(langs, defaultLangKey, url);
  const homeLink = `/${langKey}`.replace(`/${defaultLangKey}/`, '/');
  const langsMenu = getLangs(langs, langKey, getUrlForLang(homeLink, url)).map((item) => ({ ...item, link: item.link.replace(`/${defaultLangKey}/`, '/') }));
  const { path } = useLanguages();


  const handleContextMenu = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
  }

  useEffect(() => {
    document.addEventListener('contextmenu', handleContextMenu);

    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    }
  }, []);

  return (
    <IntlProvider
      locale={langKey}
      messages={i18nMessages}
    >
      <Header siteTitle={data.site.siteMetadata.title} langs={langsMenu} />
      {children}
      <Footer />

      <CustomLink
        to={`${path}contact`}
        className="contact-button"
        aria-label="Contact us"
      >
          <Icon icon="commenting" />
      </CustomLink>
    </IntlProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  i18nMessages: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Layout;
