import { useStaticQuery, graphql } from 'gatsby';

// Assets & Misc
import { getLanguage } from '../utils/getters';

export const useLanguages = () => {
  const data = useStaticQuery(graphql`
    query UseLanguageQuery {
      site {
        siteMetadata {
          languages {
              defaultLangKey
              langs
          }
        }
      }
    }
  `);
  const langKey = getLanguage(data.site.siteMetadata.languages);
  return { langKey, path: langKey === 'en' ? '/en/' : '/' }
}