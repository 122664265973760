import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

// Assets & Misc
import { getLanguage } from '../../utils/getters';
import { CustomLink } from '../../utils/navigation';

const Footer = () => {
  const data = useStaticQuery(graphql`
  query FooterTitleQuery {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`);

  // Assets & Misc
  const langKey = getLanguage(data.site.siteMetadata.languages);
  return (
    <footer>
      <div className="ftr-section">
        <div className="container">
          <ul className="footer-info">
            <li className="ftr-phn">+57 (2) 308 9889</li>
            <li className="ftr-wsp">
              <a style={{ color: 'inherit' }} href="https://api.whatsapp.com/send?phone=573137849119" target="_blank" rel="noopener noreferrer">+57 313 784 9119</a>
            </li>
            <li className="ftr-msg">info@protecnoing.com</li>
            <li className="ftr-support"><FormattedMessage id="workingHours" /></li>
          </ul>
          <div className="row">
            {/* About us */}
            <div className="col-md-5 col-sm-6 col-xs-12  ftr-about-text">
              <FormattedMessage id="aboutUs" tagName="h6" values={{ prefix: '' }} />
              <p className="marbtm20 line-height26"><FormattedHTMLMessage id="shortIndexAboutUsdescription" /></p>
              <CustomLink to={langKey === 'en' ? '/en/about' : '/about'} className="ftr-read-more">
                <FormattedMessage id="readMore" />
              </CustomLink>
            </div>
            {/* Products */}
            <div className="col-md-5 col-sm-6 col-xs-12 ftr-sol-column">
              <FormattedMessage id="ourProducts" tagName="h6" />
              <ul className="footer-a">
                <li>
                  <CustomLink to={`${langKey === 'en' ? '/en/products' : '/products'}?family=adhesivesAndIndustrialSealants`}>
                    - <FormattedMessage id="adhesivesAndIndustrialSealantsMenu" />
                  </CustomLink>
                </li>
                <li>
                  <CustomLink to={`${langKey === 'en' ? '/en/products' : '/products'}?family=advancedPolymerCoatings`}>
                    - <FormattedMessage id="advancedPolymerCoatingsMenu" />
                  </CustomLink>
                </li>
                <li>
                  <CustomLink to={`${langKey === 'en' ? '/en/products' : '/products'}?family=elastomericThermalInsulations`}>
                    - <FormattedMessage id="elastomericThermalInsulationsMenu" />
                  </CustomLink>
                </li>
                <li>
                  <CustomLink to={`${langKey === 'en' ? '/en/products' : '/products'}?family=plasmaSurfaceTreatment`}>
                    - <FormattedMessage id="plasmaSurfaceTreatmentMenu" />
                  </CustomLink>
                </li>
              </ul>
            </div>
            {/* Quick links */}
            <div className="col-md-2 col-sm-6 col-xs-12 ftr-a-column">
              <FormattedMessage id="quickLinks" tagName="h6" />
              <ul className="footer-a">
                <li><CustomLink to={langKey === 'en' ? '/en/about' : '/about'}><FormattedMessage id="aboutUs" values={{ prefix: '- ' }} /></CustomLink></li>
                <li><CustomLink to={`${langKey === 'en' ? '/en/products' : '/products'}?family=adhesivesAndIndustrialSealants`}><FormattedMessage id="products" values={{ prefix: '- ' }} /></CustomLink></li>
                <li><CustomLink to={langKey === 'en' ? '/en/services' : '/services'}><FormattedMessage id="services" values={{ prefix: '- ' }} /></CustomLink></li>
                <li><CustomLink to={langKey === 'en' ? '/en/multimedia' : '/multimedia'}><FormattedMessage id="multimedia" values={{ prefix: '- ' }} /></CustomLink></li>
                <li><CustomLink to={langKey === 'en' ? '/en/contact' : '/contact'}><FormattedMessage id="contactUs" values={{ prefix: '- ' }} /></CustomLink></li>
              </ul>
            </div>
          </div>
          {/* Copyright */}
          <div className="footer-btm">
            <div className="col-md-6 col-sm-6 col-xs-12 pad-left_zero pad-right_zero">
              <p>{`Copyright © 2010 - ${(new Date()).getFullYear()} Protecno Ingeniería®. All Rights Reserved.`}</p>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12 pad-left_zero pad-right_zero pull-right">
              <p className="text-right" style={{ textAlign: 'end' }}>
                <a href="/POLITICA-DE-TRATAMIENTO-DE-DATOS-CLIENTES.pdf" target="_blank" rel="noopener noreferrer" className="politics" style={{ color: '#ffffff' }}>
                  <FormattedMessage id="dataProcessing" />
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
