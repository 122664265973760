import React from 'react';

// Own components
import Navbar from './navbar';

// Assets & Misc
import { useLanguages } from '../../hooks/useLanguages';
import { CustomLink } from '../../utils/navigation';

const Header = () => {
  const { langKey, path } = useLanguages();

  return (
    <header className="header1">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 display-block ">
            <CustomLink to={langKey === 'en' ? '/en' : '/'} className="logo">
              <img data-src="/images/protecno.png" className="img-responsive lazyload" alt="Protecno Ing" />
            </CustomLink>
          </div>
        </div>
      </div>
      <Navbar langKey={langKey} path={path} />
    </header>
  );
};

export default Header;
