import React from 'react';

const SectionCover = ({ title, src = 'http://via.placeholder.com/1920x365' }) => (
  <div className="inner-pages-bnr" style={{ position: 'relative' }}>
    <img src={src} className="img-responsive" alt="Service-banner" />
    <div className="banner-caption" style={{ zIndex: 2 }}>
      <h1>{title}</h1>
    </div>
  </div>
);

export default SectionCover;
